.text-muted,
.mutedText {
  color: $color-light-gray !important;
}

.text-center,
.centerText {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.noMarginTop {
  margin-top: 0 !important;
}

.noDisplay {
  display: none !important;
}

.noBorderRadius {
  border-radius: 0 !important;
}

.fullWidth {
  width: 100% !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: calc(50% - 2em);
}

.column-1 {
  flex-basis: 0.083%;
  max-width: 0.083%;
}

.column-2,
.col-2 {
  flex-basis: 16.66%;
  max-width: 16.66%;
}

.column-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.column-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.column-7 {
  flex-basis: 58.33%;
  max-width: 58.33%;
}

.column-8 {
  flex-basis: 66.67%;
  max-width: 66.67%;
}

.column-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.column-10,
.col-10 {
  flex-basis: 83.3%;
  max-width: 83%;
}

.column-11 {
  flex-basis: 91.6%;
  max-width: 91.6%;
}

a.alternative-link {
  color: $color-light-blue;
}

.float-right {
  float: right !important;
}

.iconContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

.iconContainer.paymentMethod {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  right: 0;
  display: flex;
  top: 50%;
}

.iconContainer.paymentMethod img {
  width: 100%;
}

.showPaymentIcon {
  opacity: 1 !important;
  transition: all 0.4s ease-out !important;
  transform: translateY(-50%) rotateY(0deg) !important;
}

.mainContainer {
  background-image: url('~assets/images/bg.svg');
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 29px;
  padding-bottom: 30px;

  &:empty {
    display: none;
  }
}
