$color-white: #ffffff;
$color-lightest-gray: #f0f0f0;
$color-light-gray: #a9a9a9;
$color-light-blue: #39d1d6;
$color-dark-blue: #3a559f;
$color-red: #dd4b39;
$color-light-red: #ffe8e8;
$color-alternative-black: #343434;
$color-black: #1c1c1c;
$color-alternative-red: #b20101;
$color-mostly-white: #f8f8f8;

$stdBorderRadiusWidth: 7px;

$breakpoint-min: 320px;
$breakpoint-mobile2: 500px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;
