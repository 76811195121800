@import './reset';
@import './fonts';
@import './variables';
@import './mixins';
@import './helpers';

@import '~react-image-gallery/styles/css/image-gallery.css';

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

html,
body {
  font-family: HelveticaNeue, sans-serif;
  font-weight: 300;
  color: $color-alternative-black;
  background-color: $color-white;
  min-width: $breakpoint-min;
  position: relative;
}

input,
textarea,
button {
  font-family: inherit;
  border: 0;
  outline: none;
  box-sizing: border-box;
}

input {
  &[type='search'] {
    -webkit-appearance: textfield;

    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  &::-webkit-search-cancel-button {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }
}

textarea {
  &::-webkit-input-placeholder {
    color: $color-light-gray;
  }

  &:-moz-placeholder {
    color: $color-light-gray;
  }

  &::-moz-placeholder {
    color: $color-light-gray;
  }

  &:-ms-input-placeholder {
    color: $color-light-gray;
  }

  &::placeholder {
    color: $color-light-gray;
  }
}

a {
  text-decoration: none;
  color: $color-white;
}

.ReactModalPortal {
  position: relative;
  z-index: 10000;
}
