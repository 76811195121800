@font-face {
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: normal;
  src: url('~assets/fonts/HelveticaNeueLTStd-Roman.otf')
    format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-weight: 500;
  src: url('~assets/fonts/HelveticaNeueLTStd-Md.otf')
    format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  font-weight: 700;
  src: url('~assets/fonts/HelveticaNeueLTStd-Bd.otf')
    format('opentype');
  font-style: normal;
}
